import "@fontsource/noto-sans-jp/400.css"
import './global.css';
import { ChakraProvider, extendTheme } from "@chakra-ui/react"
import React from "react"
const theme = extendTheme({
    fonts: {
        heading: `'Noto Sans JP', sans-serif`,
        body: `'Noto Sans JP', sans-serif`,
    },
})
export const wrapPageElement = ({ element }) => {
    return (
        <ChakraProvider theme={theme}>
            {element}
        </ChakraProvider>
    )
}